// import { useState } from 'react';
import './Team.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Container from '../Container/Container';



const Team = () => {
    const options = {
        items: 3,
        autoplay: true,
        nav: false,
        margin:25,
        rewind: true,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:3
            },
            1000:{
                items:3
            }
        }
    };
    const items = [
        {id: 1, image:"./assets/teams/01.png", name: 'Sabah Mujalled', title: 'Marketing manager'},
        {id: 2,image:"./assets/teams/02.png", name: 'Saba Alshehab', title: 'Design  Engineer'},
        {id: 3,image:"./assets/teams/03.png", name: 'Nader Hatoum', title: 'Manufacture Engineer'},
        {id: 4,image:"./assets/teams/04.png", name: 'Khalid Al-Sheikh', title: 'Design Engineer'},
        {id: 5,image:"./assets/teams/05.png", name: 'Lara Alghamdi', title: 'Engineering Manager'},
        {id: 6,image:"./assets/teams/06.png", name: 'Rawan Sonbul', title: 'Project Manager'}
    ];
    
    return (
        <div className='mt-5 mb-5' id='team'>
            <div className='team mt-5'>
                <Container>
                <h2>Meet our team</h2>
                <OwlCarousel className="owl-theme" {...options}>
                    {items.map((item, index) => {
                        return (
                        <div key={item.id}>
                            <img src={item.image} alt="" />
                            <h3>{item.name}</h3>
                            <h4>{item.title}</h4>
                        </div>
                        )   
                    })}
                </OwlCarousel>
                </Container>
        </div>
        </div>

    );
}

export default Team;
