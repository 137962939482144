import './Sponsers.css';

const Sponsers = () => {

    const logos = [
        {
            id: 1,
            company: './assets/logos/ABE-logo.png',
        },
         {
             id: 2,
             company: './assets/logos/smart-logo.png',
        },
        {
            id: 3,
            company: './assets/logos/Saudi-Aramco-Logo.png',
        },
        {
            id: 4,
            company: './assets/logos/ithra-Logo.png',
        },
        {
            id: 5,
            company: './assets/logos/school.png',
        },
        {
            id: 6,
            company: './assets/logos/Aut-logo.png',
        },
        {
            id: 7,
            company: './assets/logos/sado-logo.png',
        }
    ];
      
    return (
        <div data-section id='sponsers'>
        <div className="h-5rm"></div>
        <div className="container-fluid">
            <div className="sponsers-container">
                <div className="d-flex align-items-center justify-content-center mt-5">
                    <h2 className="text-center title d-inline-block">Sponsers</h2>
                </div>
                <div className="d-flex align-items-center justify-content-center mt-5 mb-5">
                    {logos.map(logo => {
                        return (
                        <div className="logo px-4" key={logo.id}>
                            <img src={logo.company} height={80} alt="" />
                        </div>
                         )
                        })}
                    {/* <div className="logo px-4">
                        <img src="./assets/logos/logo2.svg" height={200} alt="" />
                    </div> */}
                    {/* <div className="logo px-4">
                        <img src="./assets/logos/display_car_black 1.png" height={85} alt="" />
                    </div>
                    <div className="logo px-4">
                        <img src="./assets/logos/Saudi-Aramco-Logo.png" height={85} alt="" />
                    </div>
                    <div className="logo px-4">
                        <img src="./assets/logos/school.png" height={85} alt="" />
                    </div> */}
                </div>
            </div>
        </div>
        <div className="h-5rm"></div>
        </div>
    );
}

export default Sponsers;
