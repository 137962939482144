import { useState, useEffect, useRef } from 'react';
import Container from '../Container/Container';
import './Nav.css'
const Nav = props => {
    const {logo} = props;
    const menus = [
        {
            name: 'home',
            id: 1
        },
         {
            name: 'about',
            id: 2
        },
        {
            name: 'team',
            id: 3
        },
        {
            name: 'Sponsers',
            id: 4
        }
    ];


    /**/

    const [activeSection, setActiveSection] = useState(null);
    const sections = useRef([]);
  
    const handleScroll = () => {
      const pageYOffset = window.pageYOffset;
      let newActiveSection = null;
  
      sections.current.forEach((section) => {
        const sectionOffsetTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;
  
        if (pageYOffset >= sectionOffsetTop && pageYOffset < sectionOffsetTop + sectionHeight) {
          newActiveSection = section.id;
        }
      });
  
      setActiveSection(newActiveSection);
    };
  
    useEffect(() => {
      sections.current = document.querySelectorAll('[data-section]');
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    const activeStyle = {
      fontWeight: 'bold',
      color: '#EBFF00',
      textDecoration: 'none',
    };

    /**/
  
    return (
        <>
        <nav className="navbar navbar-expand-lg bg fixed-top" data-aos="fade-down">
            <Container>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse justify-content-start" id="navbarText">
                <ul className="navbar-nav mb-2 mb-lg-0">
                    {menus.map((item) => {
                        return (
                        <li 
                            key={item.id}
                            // className={`nav-item ${active === item.id && 'active' | 'undefine' }`}
                            className={`nav-item ${activeSection === item.name.toUpperCase() ? 'active' : ''}`}>
                        <a className="nav-link" aria-current="page" href={`#${item.name.toLowerCase()}`} style={activeSection === 
        item.name ? activeStyle : {}}>{item.name.toUpperCase()}</a>
                        </li>
                        )
                    })}
                    {/* <li className="nav-item">
                    <a className="nav-link" href="#about">About </a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link" href="#team">Our team</a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link" href="#sponsers">Sponsers</a>
                    </li> */}
                </ul>
            </div>
            <a className="navbar-brand" href="#hero"><img src={logo} alt="" width={140} /></a>
            </Container>
        </nav>

        </>
    );
}

export default Nav;
