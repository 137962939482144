import {Nav, Hero, About, Team, Sponsers, Footer} from './components/index'
import './App.css';
import AOS from 'aos';
import "aos/dist/aos.css";
import { useEffect } from 'react';

function App() {



  useEffect(() => {

    AOS.init({
      duration : 2000,
      once: true,
    });
  }, []);

  return (
    <div className="App">
      <Nav logo= "./logo.svg" />
      <Hero />
      <About />
      <Team />
      <Sponsers />
      <Footer /> 
    </div>
  );
}

export default App;
