import OwlCarousel from 'react-owl-carousel';
import './About.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Container from "../Container/Container";
const About = () => {

    const options = {
        items: 3,
        // autoplay: true,
        nav: false,
        margin:50,
        rewind: true,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:1
            },
            1000:{
                items:1
            }
        }
    };
    const items = [
        {
            id: 1, 
            header:"Who are we",
            copy: 'We are an F1 in Schools team made of passionate and talented students who aim to win the F1 in Schools international finals that will be held in Singapore.'

        },  
        {
            id: 2, 
            header:"What is F1 in Schools",
            copy: 'F1 in Schools is an international STEM competition where students design and manufacture a car. F1 in Schools is a huge opportunity for youths and youngsters to learn, brainstorm, and create.'
        },  
        {
            id: 3, 
            header:"Vision",
            copy: "To win the world's F1 In Schools championship."
        },  
        {
            id: 4, 
            header:"Mission",
            copy: 'Our mission is to influence and inspire future generations and encourage them to participate in international competitions and maintain sustainability in our society'
        },  
        {
            id: 5, 
            header:"Goals",
            copy: '-To win the F1 in Schools finals that will be held in Singapore -Develop our skills in different fields of STEM -Inspire youths and future generations to engage in international STEM competition -To maintain the 17 sustainable development goals. -Create and build relationships with people from different fields (networking)'
        },  
    ];


    return (
        <>
        <Container>
            <div id="about"></div>
            <div className="row about-wraper align-items-center text-left">
                <div className="col-md-12" data-aos="fade-left">
                    <div className="px-5">
                        {/* <h2>About us</h2> */}
                        {/* <p>"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."</p> */}
                        <OwlCarousel className="owl-theme" {...options}>
                            {items.map((item, index) => {
                                return (
                                <div key={item.id}>
                                    <h2>{item.header}</h2>
                                    <p>{item.copy}</p>
                                </div>
                                )   
                            })}
                        </OwlCarousel>

                    </div>
                </div>
                {/* <div className="col-md-6" data-aos="fade-right">
                    <img src="./assets/digital.svg" width="100%" alt="" />
                </div> */}
            </div>
            <div className="h-5rm"></div>
        </Container>
        </>
    );
}

export default About;
