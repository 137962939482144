import Container from '../Container/Container';
import './Hero.css';
  
const Hero = () => {
    return (
        <div data-section className="hero-wraper" id='home'>
            <Container>
                <div data-aos="fade-down">
                    <img src="./logo.svg" className='logo' width={400} alt="" />
                </div>
                <h1  data-aos="flip-up">Think like a Romer</h1>
                <a href="https://www.linkedin.com/in/io-velocita-540778263/"  data-aos="zoom-in">
                    <img src="./assets/social/in.svg" alt="" />
                </a>
                <a href="https://www.instagram.com/io_velocita/?igshid=YmMyMTA2M2Y%3D" data-aos="zoom-in">
                    <img src="./assets/social/Instagram.svg" alt="" />
                </a>
                <a href="https://www.tiktok.com/@singaporewearecom" data-aos="zoom-in">
                    <img src="./assets/social/tiktok.svg" alt="" />
                </a>
                <a href="https://twitter.com/io_velocita" data-aos="zoom-in">
                    <img src="./assets/social/twitter.svg" alt="" />
                </a>
            </Container>
        </div>
    );
}

export default Hero;
