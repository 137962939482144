import Container from '../Container/Container';
import './Footer.css'


const Footer = () => {
    return (
        <footer>
            <Container>
                <div data-aos="fade-down">
                    <img src="./logo.svg" className='logo' width={400} alt="" />
                </div>
                <br /><br />
                <a href="https://www.linkedin.com/in/io-velocita-540778263/"  data-aos="zoom-in">
                    <img src="./assets/social/in.svg" height={30} alt="" />
                </a>
                <a href="https://www.instagram.com/io_velocita/?igshid=YmMyMTA2M2Y%3D" data-aos="zoom-in">
                    <img src="./assets/social/Instagram.svg" height={30} alt="" />
                </a>
                <a href="https://www.tiktok.com/@singaporewearecom" data-aos="zoom-in">
                    <img src="./assets/social/tiktok.svg" height={30} alt="" />
                </a>
                <a href="https://twitter.com/io_velocita" data-aos="zoom-in">
                    <img src="./assets/social/twitter.svg" height={30} alt="" />
                </a>

                <div className="sponsorbtn" data-aos="fade-up">
                    <a href="!">sponsor</a>
                </div>
            </Container>
        </footer>
    );
}

export default Footer;
